import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import Image from "gatsby-image"
import Logo from "../components/logo"
import { rhythm } from "../utils/typography"
import SEO from "../components/seo"

const Splash = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  @media (max-width: 599px) {
    grid-template-rows: auto auto;
  }
  @media (min-width: 600px) {
    grid-template-columns: 1fr 2fr;
  }
`
const SplashText = styled.div`
  padding: ${rhythm(2)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: rgba(137, 123, 114, 0.5); */
`
const SplashImage = styled(Image)`
  height: 100%;
  width: 100%;
`
export default ({ data }) => (
  <>
    <Splash>
      <SEO title="Home" />
      <SplashText>
        <h2>
          <Logo />
        </h2>
        <p>
          Whitehead Carvings is an industry leader in high-end woodworking. We
          offer custom designed relief carvings in doors, mantle pieces, posts,
          and wood furniture. We also offer large scale sculptures and chainsaw
          carvings. You can find more information about our work here.
        </p>
        <p>
          Bob Whitehead, the face of Whitehead Carvings, has over 35 years of
          experience in the craft and is also an accomplished wildlife artist.
          He lives with his family in beautiful Salmon Arm, British Columbia.
          Whether he is riding horses in the back of his property or fishing on
          the Shuswap, Bob continues to live close to nature to maintain the
          authenticity expressed in his work.
        </p>
      </SplashText>
      <SplashImage fluid={data.bob.childImageSharp.fluid} />
    </Splash>
    <Layout at={"home"} hide={true}>
    </Layout>
  </>
)

export const query = graphql`
    query {
      bob: file(relativePath: {eq: "images/home/bobs-bears.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
