import React from "react"
import { rhythm } from "../utils/typography"
import styled from "styled-components"

const Logo = styled.span`
  white-space: nowrap;
`
const Whitehead = styled.span`
  &:before {
    content: "Whitehead";
    font-weight: 500;
  }
`
const Heron = styled.svg`
  height: ${rhythm(3)};
  margin-left: ${rhythm(-1)};
  margin-right: ${rhythm(-1.5)};
  fill: currentColor;
`
const Carvings = styled.span`
  &:after {
    content: "Carvings";
  }
`

export default () => (
  <Logo>
    <Whitehead />
    <Heron viewBox="0 0 38 38">
      <title>Heron</title>
      <path
        transform="translate(-74.33 -22.6)"
        d="M111.22,26.89a.06.06,0,0,0,0-.11c-1.82-.78-4.7-1.21-7.79-2.44-2.87-1.14-2.34-1.75-4.32-1.68-2.91.1-4.18,3.94-10.48,7.2a46,46,0,0,0,4.66-2.57s.1,0,.08.06a4.73,4.73,0,0,0-.26,1.21,4.94,4.94,0,0,1,1.41-2.38,0,0,0,0,1,.08.05,6.6,6.6,0,0,0,.64,3.86c1.85,3.53,3.16,2.39,3.73,4.25.66,2.18.44,3.2-.45,4.13-.61.65-1.32-1.87-4.14-2.08-.86-.06-2.46-.2-8.82,3.37-4.08,2.29-8,7.27-9.86,8.83-1.06.88-.75,1.3-.06,1,0,0,.09,0,.06.08C74.56,51,74.3,51.72,74.4,52s.45.17.91.16c0,0,.1,0,.07.06-.3.46-.8,1.08,2.45.19,3.54-1,.19-1.51,9.25-1.58a.06.06,0,0,1,.06.06c-.07,1.61.55,3.67.32,5.69-.09.84-.17,2.9-.15,4.42.36,0,.6,0,1,0,.06-2.7.2-5.82.34-7.41,0,0,.24-2.53,1.15-2.94a3.56,3.56,0,0,1,1,0s0,0,0,0a29.73,29.73,0,0,1,.53,4c0,2.12.08,4.23,0,6.35h1c-.06-.86-.11-2.17-.13-2.72a14.6,14.6,0,0,1,.17-3.89c.32-1.52.6-1.77.89-3.1s.26-2,.92-2.44c.34-.22.44-.23.88-.52A3.21,3.21,0,0,0,96.31,47,8.38,8.38,0,0,1,95,49.43c.95-1.54,2-2.92,2.2-2.83a4.73,4.73,0,0,1-.64,1.94c1.47-2.57,2.32-3.88,2.49-3.81s-.28,1-1,2.43c.78-1.48,1.75-2.58,2.16-3.45,2.22-4.73,2.53-6.83,2.44-7.91A7.18,7.18,0,0,0,101,31.2c-.73-1-2.41-.23-3.18-2.6h0c-.25-1.68.78-1.65,1.6-2,2-.79,3.56-.14,7,.18A23.46,23.46,0,0,0,111.22,26.89Z"
      />
    </Heron>
    <Carvings />
  </Logo>
)
